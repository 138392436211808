<template>
    <div class="course">
        <banner :message="banner.message"
                :message-type="banner.messageType"
                :is-shown="banner.isBannerShown"
                :banner-close="banner.bannerClose"
        />
        <div class="course__stripe" />
        <figure class="course__figure p-4" @click="pushToLessons">
            <img v-if="course.artwork" :src="course.artwork">
            <img v-else
                 src="@/assets/img/course.png"
                 class="h-2/3"
            >
        </figure>
        <div class="course__title">
            <h1 @click="pushToLessons">
                {{ course.title }}
            </h1>
        </div>
        <div class="course__description">
            <p> {{ course.description }} </p>
        </div>
        <div class="course__published">
            <p v-if="course.completed" class="publish__tag">
                Published
            </p>
        </div>
        <div class="course__actions">
            <div class="course__actions--left">
                <button class="publish__button"
                        type="button"
                        @click="publishCourse"
                >
                    {{ course && course.completed ? "unpublish" : "publish" }}
                </button>
                <button class="course__delete" @click="toggleModal">
                    <font-awesome-icon icon="trash" class="text-sm" />
                </button>
                <button class="course__edit" @click="pushToEdit">
                    <font-awesome-icon icon="pencil-alt" class="text-sm" />
                </button>
                <modal :is-modal-open="modal.isModalOpen"
                       :toggle-modal="toggleModal"
                >
                    <h1 slot="title" class="modal__title">
                        Delete Tutorial
                    </h1>
                    <div slot="content" class="modal__content">
                        <p class="modal__subtitle">
                            Are you sure you want to delete this tutorial
                        </p>
                        <toggle-buttons :handler="toggleHandler"
                                        :names="buttonNames"
                        />
                    </div>
                </modal>
            </div>
            <div class="course__actions-right">
                <button class="text-sm hover:text-accent px-3 py-2 font-medium text-gray-700"
                        @click="pushToLessons"
                >
                    Lessons
                    <font-awesome-icon icon="arrow-right" class="ml-2 text-sm" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ToggleButtons from '@/components/ui/ButtonToggle.vue';
import Banner from '@/components/ui/Banner.vue';
import { bannerMixin } from '@/mixins/Banner';
import Modal from '@/components/ui/Modal.vue';

export default {
    components: {
        Modal,
        ToggleButtons,
        Banner,
    },
    mixins: [bannerMixin],
    props: {
        course: {
            type: Object,
            default: () => ({
                id: '',
                title: '',
                artwork: '',
            }),
        },
    },
    data() {
        return {
            modal: {
                isModalOpen: false,
            },
            buttonNames: {
                positive: 'delete',
                negative: 'cancel',
            },
        };
    },

    methods: {
        ...mapActions('courses', {
            deleteCourseImpl: 'deleteCourse',
            setCurrentCourse: 'setCurrentCourse',
            modifyCourse: 'modifyCourse',
        }),

        toggleModal() {
            this.modal.isModalOpen = !this.modal.isModalOpen;
        },

        async toggleHandler(state) {
            if (state) {
                try {
                    const result = await this.deleteCourse();
                    if (result.deleted) {
                        this.renderBanner('success', 'course deleted');
                        this.toggleModal();
                    }
                } catch (ex) {
                    this.renderBanner('error', 'failed to delete course');
                    this.toggleModal();
                }
                return;
            }
            this.toggleModal();
        },

        async deleteCourse() {
            const result = this.deleteCourseImpl(this.course.id);
            return result;
        },

        pushToEdit(e) {
            e.stopPropagation();
            this.$router.push({
                path: `/courses/edit/${this.course.id}`,
            });
        },

        pushToLessons(e) {
            e.stopPropagation();
            this.setCurrentCourse({ courseId: this.course.id });
            this.$router.push({
                path: `/courses/${this.course.id}/lessons/dashboard`,
            });
        },

        async publishCourse() {
            const noLessons = this.course.lessons.length === 0;
            const courseNotPublished = this.course.completed === false;
            const noArtwork = !this.course.artwork;

            if (noArtwork && courseNotPublished) {
                this.renderBanner(
                    'warning',
                    'Cannot publish a tutorial with no artwork',
                );
                return;
            }
            if (noLessons && courseNotPublished && noArtwork) {
                this.renderBanner(
                    'warning',
                    'Cannot publish a tutorial with no lessons',
                );
                return;
            }
            await this.modifyCourse({
                courseId: this.course.id,
                updates: {
                    completed: !this.course.completed,
                },
            });
        },
    },

};
</script>

<style lang="scss" scoped>
.course {
    @apply h-64;
    @apply w-full;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply rounded-tr-md;
    @apply rounded-br-md;
    @apply overflow-hidden;
    @apply shadow-md;
    @apply grid;
    grid-template-columns: 4px 40% 1fr;
    grid-template-rows: 4rem 1fr 2rem 4rem;
    grid-template-areas:
        "stripe image title"
        "stripe image description"
        "stripe image publish"
        "stripe image actions";
}

.course__stripe{
    grid-area: stripe;
    @apply h-full;
    @apply bg-accent;
}

.course__figure {
    grid-area: image;
    @apply flex;
    @apply items-center;
    @apply overflow-hidden;
    @apply object-cover;
    @apply justify-center;
    @apply cursor-pointer;
}

.course__title {
    grid-area: title;
    @apply flex;
    @apply items-end;
    @apply px-3;
    @apply w-full;
    @apply overflow-hidden;
    & > h1 {
        @apply whitespace-no-wrap;
        @apply text-xl;
        @apply font-semibold;
        @apply cursor-pointer;
        @apply text-gray-900;
        @apply w-full;
        @apply capitalize;
        @apply overflow-hidden;
        text-overflow: ellipsis;
    }
}

.course__description {
    grid-area: description;
    @apply p-3;
    @apply pt-4;
    overflow: hidden;
    & > p {
        @apply w-full;
        @apply text-sm;
        -webkit-line-clamp: 3;
        @apply cursor-pointer;
        @apply overflow-hidden;
        @apply text-gray-700;
        @apply whitespace-normal;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }
}

.course__published {
    grid-area: publish;
    @apply w-full;
    @apply px-3;
    @apply flex;
    @apply justify-end;
    @apply mt-1;
}

.course__actions {
    grid-area: actions;
    @apply flex;
    @apply justify-between;
    @apply w-full;
    @apply items-center;
    @apply p-3;
    &--left{
        @apply flex;
        @apply items-center;
    }
    &--right{
        @apply flex;
        @apply items-center;
    }
}

.course__delete,
.course__edit {
    @apply h-8;
    @apply w-8;
    @apply p-0;
    @apply text-sm;
    @apply bg-none;
    @apply text-gray-700;
}
.course__delete {
    @apply ml-3;
    &:hover,
    &:focus {
        @apply text-red-600;
    }
}
.course__edit {
    &:hover,
    &:focus {
        @apply text-orange-600;
    }
}

.publish__button {
    @apply px-3;
    @apply py-2;
    @apply text-gray-700;
    @apply flex;
    @apply items-center;
    @apply text-xs;
    @apply font-normal;
    @apply justify-center;
    @apply rounded-md;
    @apply border;
    @apply border-gray-300;
    &:hover{
        @apply shadow-xs;
        @apply text-gray-800;
    }
}

.publish__tag {
    @apply text-xs;
    @apply px-2;
    @apply py-1;
    @apply flex;
    @apply border;
    @apply border-gray-300;
    @apply items-center;
    @apply rounded-full;
    @apply text-gray-700;
    width: max-content;
}
</style>
