<template>
    <div class="toggle" :class="className">
        <button :type="types.negative"
                class="button toggle__button left"
                :class="{ active: isNegativeActive }"
                @click="negative"
        >
            <slot name="trueContent">
                {{ names.negative }}
            </slot>
        </button>
        <button :type="types.positive"
                class="button toggle__button right"
                :class="{ active: isPositiveActive }"
                @click="positive"
        >
            <slot name="falseContent">
                {{ names.positive }}
            </slot>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        handler: {
            type: Function,
            default: () => {},
        },
        default: {
            type: Boolean,
            default: false,
        },
        names: {
            type: Object,
            default: () => ({
                positive: 'yes',
                negative: 'no',
            }),
        },
        types: {
            type: Object,
            default: () => ({
                positive: 'button',
                negative: 'button',
            }),
        },
        className: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isPositiveActive: true,
            isNegativeActive: false,
        };
    },
    created() {
        if (this.default) {
            this.isPositiveActive = true;
            this.isNegativeActive = false;
        } else {
            this.isPositiveActive = false;
            this.isNegativeActive = true;
        }
    },
    methods: {
        async positive(e) {
            e.preventDefault();
            this.isNegativeActive = false;
            this.isPositiveActive = true;
            await this.handler(true);
            this.isPositiveActive = false;
            this.isNegativeActive = true;
        },
        async negative() {
            this.isNegativeActive = true;
            this.isPositiveActive = false;
            await this.handler(false);
        },
    },
};
</script>

<style lang="scss">
.toggle {
    @apply flex;
    @apply w-250;
    @apply h-auto;
    @apply p-0;
}
.toggle__button {
    @apply flex-1;
    @apply rounded-md;
    @apply shadow-sm;
    @apply h-12;
    @apply bg-white;
    @apply text-gray-700;
    @apply text-base;
    @apply font-medium;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply mx-2;
    &.left {
        @apply border;
        @apply border-solid;
        @apply border-gray-300;
        @apply bg-gray-100;
        &:hover,&:focus {
            @apply text-gray-900;
        }
    }
    &.right {
       @apply bg-accent;
       @apply text-white;
    }
}
</style>
