<template>
    <div class="wrapper">
        <banner :message="banner.message"
                :message-type="banner.messageType"
                :is-shown="banner.isBannerShown"
                :banner-close="banner.bannerClose"
        />
        <nav-bar view="courses" />
        <div class="content__wrapper w-screen-4/5">
            <div class="courses__header">
                <h1 class="text-3xl font-bold text-gray-900">
                    Tutorials
                </h1>
            </div>
            <div class="courses__actions">
                <p class="courses__subtitle">
                    {{ `Showing ${courses.length} tutorial(s)` }}
                </p>
                <section class="flex">
                    <button class="courses__button" @click="syncCourseIndexes">
                        <font-awesome-icon icon="sync" class="mr-2" />
                        Sync Index
                    </button>
                    <button class="courses__button ml-2" @click="openCreateView">
                        <font-awesome-icon icon="pencil-alt" class="mr-2" />
                        Create
                    </button>
                </section>
            </div>
            <div v-if="isfetching" class="course__messageBox">
                <loader />
                <p>Fetching your tutorials please wait ...</p>
            </div>
            <div v-else class="courses__container">
                <div v-if="courses.length === 0" class="w-full h-auto">
                    <p class="mx-auto w-auto text-gray-600 font-medium">
                        No courses
                    </p>
                </div>
                <template v-else>
                    <course-card v-for="(course, index) in courses"
                                 :key="index"
                                 :course="course"
                    />
                </template>
            </div>
            <div class="h-auto mb-20">
                <nav class="bg-white px-4 py-3 flex items-center
                 justify-between border-t border-gray-200 sm:px-6"
                     aria-label="Pagination"
                >
                    <div class="hidden sm:block">
                        <p class="text-sm text-gray-700">
                            {{ isfetching ? "Fetching " : "" }}Page
                            <span class="font-medium">
                                {{ currentPage }}
                            </span>
                        </p>
                    </div>
                    <div class="flex-1 flex justify-between sm:justify-end">
                        <button
                            type="button"
                            class="relative inline-flex items-center px-4
                           py-2 border border-gray-300 text-sm font-medium
                         rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            @click.stop="fetchCoursesImpl('prev')"
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            class="ml-3 relative inline-flex items-center
                           px-4 py-2 border border-gray-300
                        text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            @click.stop="fetchCoursesImpl('next')"
                        >
                            Next
                        </button>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import NavBar from '@/components/ui/Navbar.vue';
import Loader from '@/components/ui/Loader.vue';
import Banner from '@/components/ui/Banner.vue';
import CourseCard from '@/components/courses/CourseCard.vue';

import { bannerMixin } from '@/mixins/Banner';

export default {
    components: {
        NavBar,
        Banner,
        CourseCard,
        Loader,
    },

    mixins: [bannerMixin],

    data() {
        return {
            isfetching: true,
            currentCoursePage: 0,
        };
    },

    computed: {
        ...mapGetters('courses', ['courses']),
        ...mapState('courses', ['page']),
        currentPage() {
            return this.page + 1;
        },
    },

    async mounted() {
        this.isfetching = true;
        await this.fetchCourses({ page: 0, limit: 5 });
        this.isfetching = false;
    },

    methods: {
        ...mapActions('courses', ['fetchCourses', 'setPage']),
        ...mapActions('auth', { syncImpl: 'sync' }),

        openCreateView() {
            this.$router.push({ name: 'createCourse' });
        },

        async syncCourseIndexes() {
            try {
                const result = await this.syncImpl();
                this.renderBanner(
                    'warning',
                    `Search index updated with ${result.length}courses(s)!`,
                );
            } catch (error) {
                this.renderBanner(
                    'error',
                    `Something happened ${error.message || error}`,
                );
            }
        },

        async fetchCoursesImpl(type = '') {
            try {
                if ((this.isfetching || this.courses.length === 0) && type === 'next') {
                    return;
                }
                this.isfetching = true;
                if (type === 'next') this.setPage(this.page + 1);
                if (type === 'prev' && this.page > 0) this.setPage(this.page - 1);
                await this.fetchCourses({ page: this.page, limit: this.limit, force: true });
                this.isfetching = false;
            } catch (err) {
                this.isfetching = false;
                throw err;
            }
        },
    },
};

</script>

<style lang="scss" scoped>
.wrapper {
    @apply w-full;
    @apply h-auto;
    min-height: calc(100vh - 4rem);
}

.content__wrapper {
    @apply relative;
    @apply h-auto;
    @apply mt-16;
    @apply mx-auto;
    @apply grid;
    min-height: calc(100vh - 4rem);
    grid-template-columns: 1fr;
    grid-template-rows: 6rem 4rem 1fr;
    grid-template-areas:
            "header"
            "toolbar"
            "courses";
}

.courses__header {
    grid-area: header;
    @apply flex;
    @apply items-center;
}

.courses__actions {
    grid-area: toolbar;
    @apply flex;
    @apply items-end;
    @apply border-b;
    @apply border-solid;
    @apply border-gray-300;
    @apply pb-2;
    @apply justify-between;
}

.courses__subtitle {
    @apply text-gray-700;
    @apply font-medium;
    @apply text-sm;
}

.courses__button {
    @apply inline-flex;
    @apply items-center;
    @apply px-4;
    @apply py-2;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply text-sm;
    @apply leading-5;
    @apply font-semibold;
    @apply rounded-md;
    @apply text-white;
    @apply bg-white;
    @apply transition-all;
    @apply duration-150;
    @apply ease-in-out;
    @apply text-gray-700;
    &:focus {
        @apply outline-none;
    }
    &:hover{
        @apply text-gray-800;
    }
}
.courses__container {
    grid-area: courses;
    @apply grid;
    @apply justify-items-center;
    @apply px-4;
    @apply py-10;
    @apply grid;
    @apply grid-cols-2;
    @apply grid-rows-none;
    @apply auto-rows-max;
    @apply gap-10;
}
</style>
