<template>
    <div class="modal" :class="{ active: isModalOpen }">
        <slot name="children">
            <div class="modal__container">
                <div class="modal__header">
                    <div class="modal__title">
                        <slot name="title" />
                    </div>
                    <div class="modal__button" @click="toggle">
                        <font-awesome-icon icon="times" />
                    </div>
                </div>
                <slot name="content" />
            </div>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        isModalOpen: {
            type: Boolean,
            default: false,
        },
        toggleModal: {
            type: Function,
            default: () => {},
        },
    },
    methods: {
        toggle() {
            this.toggleModal();
        },
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/abstracts/mixins";
.flexCenter {
    @apply flex;
    @apply justify-center;
    @apply items-center;
}

.modal {
    background: rgba(255, 255, 255, 0.6);
    @apply w-screen;
    @apply h-0;
    @apply fixed;
    @apply top-0;
    @apply left-0;
    @apply z-50;
    @extend .flexCenter;
    @apply overflow-hidden;
    transition: all 0.2s ease-in-out;
    &.active {
        @apply h-screen;
    }
}
.modal__container {
    @apply w-500;
    min-height: 25rem;
    @apply bg-white;
    @apply shadow-md;
    @apply rounded-md;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply p-6;
    @apply flex;
    @apply flex-col;
    @include responsive(phone) {
        width: 95%;
        @apply h-200;
        @apply p-2;
    }
}
.modal__header {
    @apply w-full;
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply h-16;
}
.modal__title {
    @apply flex;
    @apply items-center;
    @apply text-2xl;
    @apply text-gray-900;
    @apply font-semibold;
}
.modal__subtitle {
    @apply text-base;
    @apply font-medium;
    @apply text-gray-600;
}
.modal__button {
    @apply h-8;
    @apply w-8;
    @apply rounded-md;
    @extend .flexCenter;
    @apply text-gray-700;
    &:hover {
        @apply border;
        @apply border-gray-300;
        @apply bg-gray-100;
        @apply cursor-pointer;
    }
}
.modal__content {
    @apply flex;
    @apply flex-1;
    @apply flex-col;
    @apply items-center;
    @apply justify-evenly;
}
.modal__actions {
    width: 80%;
    min-height: 50px;
    @apply bg-red-300;
    @apply flex;
    @apply justify-center;
    @apply overflow-hidden;
}
</style>
